import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(VueRouter);
// 进度条配置
NProgress.configure({ showSpinner: false, speed: 500 })

/**
 * 路由配置
 */
const routes = [
    {
        path: "/",
        component: () => import('@/views/layout'),
        redirect: "/home",
        children: [
            {
                name: "home",
                path: "home",
                component: () => import('@/views/home')
            },
            {
                name: "tuodong",
                path: "tuodong",
                component: () => import('@/views/tuodong')
            },
            {
                name: "product_service",
                path: "product_service",
                component: () => import('@/views/product_service')
            },
            {
                name: "zhaopin",
                path: "zhaopin",
                component: () => import('@/views/zhaopin')
            },
            {
                name: "news",
                path: "news",
                component: () => import('@/views/news')
            },
            {
                name: "news/manage",
                path: "news/manage",
                component: () => import('@/views/news/news_manage')
            },
            {
                name: "contact_us",
                path: "contact_us",
                component: () => import('@/views/contact_us')
            }
        ]
    },
    {
        path: '*',
        redirect: "/"
    }
];

let router = new VueRouter({
    base: process.env.BASE_URL,
    routes
});

/**
 * 导航守卫
 */
router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
})

router.afterEach(() => {
    NProgress.done();
})

router.onError(() => {
    NProgress.done();
})

export default router;
