import Vue from 'vue'
import App from './App.vue'
import AOS from 'aos'
import router from "@/router";
import '@/components/antdv'
import '@asika32764/vue-animate/dist/vue-animate.css'
import 'aos/dist/aos.css'
import '@/assets/style/index.css'

Vue.config.productionTip = false
AOS.init();

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
