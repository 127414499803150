<template>
  <a-config-provider :locale="locale" >
    <router-view></router-view>
  </a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
  name: 'App',
  data() {
    return {
      locale: zh_CN
    }
  }
}
</script>

<style>
</style>
