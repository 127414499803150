import Vue from 'vue'

import {
    message,
    Modal,
    Table,
    FormModel,
    Input,
    Button,
    Space,
    Popconfirm,
    Select,
    Upload,
    ConfigProvider,
    Row,
    Col
} from 'ant-design-vue'

Vue.prototype.$message = message;
Vue.use(Modal);
Vue.use(Table)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(Button)
Vue.use(Space)
Vue.use(Popconfirm)
Vue.use(Select)
Vue.use(Upload)
Vue.use(ConfigProvider)
Vue.use(Row)
Vue.use(Col)
